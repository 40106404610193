@import "../../stylesheet.scss";

.mainContent {
  @media screen and (min-width: 1024px) {
    padding-top: getSpacing(8);
    height: 118vh;
  }
  @media screen and (min-width: 1440px) {
    padding-top: getSpacing(8);
    height: 106vh;
  }
  @media screen and (min-width: 1650px) {
    padding-top: getSpacing(8);
    height: 76vh;
  }
  @media screen and (min-width: 1920px) {
    padding-top: getSpacing(8);
    height: 81vh;
  }
  @media screen and (min-width: 2048px) {
    padding-top: getSpacing(8);
    height: 83vh;
  }
  flex-grow: 1;
  overflow-y: auto;
  background-color: $pengwinYellow;
}

.content {
  @media screen and (min-width: 1024px) {
    height: 170vh;
  }
  @media screen and (min-width: 1366px) {
    height: 110vh;
  }
  @media screen and (min-width: 1440px) {
    height: 100vh;
  }
  @media screen and (min-width: 1650px) {
    height: 70vh;
  }
  @media screen and (min-width: 1920px) {
    height: 75vh;
  }
  @media screen and (min-width: 2048px) {
    height: 78vh;
  }
  background-color: $pengwinFadedBlack;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.companyRow {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    padding: 0 getSpacing(10);
  }

  @media screen and (min-width: 1366px) {
    padding: 0 getSpacing(30);
  }

  @media screen and (min-width: 1920px) {
    padding: 0 getSpacing(40);
  }
  width: 100%;
  text-align: justify;
}

.heading {
  font-size: 42px;
  font-weight: bold;
  color: $pengwinBlack;
  line-height: 32px;
  margin-bottom: 50px;
}

.heading2 {
  font-size: 18px;
  font-weight: bold;
  color: $pengwinBlack;
  line-height: 12px;
}

.subHeading {
  font-size: 34px;
  font-weight: bold;
  color: $pengwinBlack;
  line-height: 44px;
  margin-bottom: 50px;
  text-align: justify;
}

.paraStyles {
  width: 80%;
  margin-top: getSpacing(5);
}

.text {
  font-size: 18px;
  font-weight: 700;
  color: $pengwinBlack;
  line-height: 27px;
  text-align: justify;
  margin-top: getSpacing(1);
  font-weight: 500;
}

.serviceLogo {
  @media screen and (min-width: 1024px) {
    width: 350px;
    height: 350px;
  }

  @media screen and (min-width: 1366px) {
    width: 350px;
    height: 350px;
  }

  @media screen and (min-width: 1440px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (min-width: 1650px) {
    width: 450px;
    height: 450px;
  }

  @media screen and (min-width: 1920px) {
    width: 500px;
    height: 500px;
  }

  @media screen and (min-width: 2048px) {
    width: 500px;
    height: 500px;
  }
  padding-top: getSpacing(1);
}
