/************************************************

              /!\ NEW UI GUIDELINE /!\

Please use the following constants to add style
to your components and pages.
The name of the constants are defined by the UIs,
so we can all (UIs/dev...) have a common language
to describe the expected style of components.

************************************************/

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
$spacingUnit: 8;
$measurementUnit: "px";

@function getSpacing($multiplier) {
  @return unquote($spacingUnit * $multiplier + $measurementUnit);
}

$pagePadding: getSpacing(2);

$breakpointMobile: 640px; // to include tablets as well

@mixin mobile {
  @media (max-width: #{$breakpointMobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: calc(#{$breakpointMobile} + 1px)) {
    @content;
  }
}

@mixin respond-to($device) {
  $breakpoint: map-get($breakpoints, $device);
  @if $breakpoint {
    @media (max-width: #{$breakpoint}) {
      @content;
    }
  }
}

//#region Color palette

/**
 * Do not use directly the color palette in your components
 * Create an entry in the color usages below instead
 */
$pengwinBlack: #232321;
$pengwinWhite: #ffffff;
$pengwinYellow: #f4e02f;
$pengwinFadedBlack: #23232120;
$pengwinBlue: #0056b3;
$pengwinBlackGlossy: #141414;
