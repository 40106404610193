@import "../../stylesheet.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
}

.header {
  position: fixed;
  height: 8%;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  text-align: center;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: $pengwinBlack;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: getSpacing(32);
}

.logo {
  width: 200px;
  height: 200px;
  padding-top: getSpacing(1);
  cursor: pointer;
}

.headerText {
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  gap: getSpacing(12);
  font-weight: bolder;
  margin-right: getSpacing(-30);
}

.button {
  cursor: pointer;
}

.bold {
  font-style: italic;
}