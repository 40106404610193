@import "../../stylesheet.scss";

$indexValue: 1000;

.mainContent {
  @media screen and (min-width: 1024px) {
    padding-top: getSpacing(8);
  }
  flex-grow: 1;
  overflow-y: auto;
  background-color: $pengwinYellow;
  height: 100vh;
}

.mainContent::-webkit-scrollbar {
  display: none;
}

.content {
  height: 60vh;
  background-color: $pengwinFadedBlack;
  display: flex;
  justify-content: center;
}

.innerWrapper {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    margin-top: getSpacing(2);
  }
  @media screen and (min-width: 1366px) {
    margin-top: getSpacing(8);
  }
  @media screen and (min-width: 1650px) {
    margin-top: getSpacing(12);
  }
  gap: getSpacing(5);
}

.codeImage {
  display: flex;
  align-items: center;

  @media screen and (min-width: 1024px) {
    width: 250px;
    height: 250px;
    margin-left: getSpacing(20);
  }

  @media screen and (min-width: 1366px) {
    width: 350px;
    height: 350px;
    margin-left: getSpacing(30);
  }

  @media screen and (min-width: 1440px) {
    width: 350px;
    height: 350px;
    margin-left: getSpacing(30);
  }

  @media screen and (min-width: 1650px) {
    width: 450px;
    height: 450px;
    margin-left: getSpacing(30);
  }

  @media screen and (min-width: 1920px) {
    width: 400px;
    height: 400px;
    margin-left: getSpacing(50);
  }

  @media screen and (min-width: 2048px) {
    width: 500px;
    height: 500px;
    margin-left: getSpacing(50);
  }
}

.codeImage1 {
  display: flex;
  align-items: center;
  @media screen and (min-width: 1024px) {
    width: 250px;
    height: 250px;
    margin-right: getSpacing(15);
  }

  @media screen and (min-width: 1366px) {
    width: 350px;
    height: 350px;
    margin-right: getSpacing(30);
  }

  @media screen and (min-width: 1440px) {
    width: 350px;
    height: 350px;
    margin-right: getSpacing(30);
  }

  @media screen and (min-width: 1650px) {
    width: 450px;
    height: 450px;
    margin-right: getSpacing(30);
  }

  @media screen and (min-width: 1920px) {
    width: 400px;
    height: 400px;
    margin-right: getSpacing(55);
  }

  @media screen and (min-width: 2048px) {
    width: 500px;
    height: 500px;
    margin-right: getSpacing(55);
  }
}

.title {
  @media screen and (min-width: 1024px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 42px;
  }
  line-height: 50px;
  text-align: right;
  letter-spacing: normal;
  font-weight: bold;
}

.textData {
  display: flex;
  flex-direction: column;
  align-items: end;
  @media screen and (min-width: 1024px) {
    margin-right: getSpacing(15);
  }

  @media screen and (min-width: 1366px) {
    margin-right: getSpacing(30);
  }

  @media screen and (min-width: 1440px) {
    margin-right: getSpacing(30);
  }

  @media screen and (min-width: 1650px) {
    margin-right: getSpacing(30);
  }

  @media screen and (min-width: 1920px) {
    margin-right: getSpacing(50);
  }

  @media screen and (min-width: 2048px) {
    margin-right: getSpacing(50);
  }
}

.textData1 {
  display: flex;
  flex-direction: column;
  align-items: end;
  @media screen and (min-width: 1024px) {
    margin-left: getSpacing(15);
  }

  @media screen and (min-width: 1366px) {
    margin-left: getSpacing(30);
  }

  @media screen and (min-width: 1440px) {
    margin-left: getSpacing(30);
  }

  @media screen and (min-width: 1650px) {
    margin-left: getSpacing(30);
  }

  @media screen and (min-width: 1920px) {
    margin-left: getSpacing(50);
  }

  @media screen and (min-width: 2048px) {
    margin-left: getSpacing(50);
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: getSpacing(5);
}

.subText {
  color: $pengwinBlack;
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  text-align: right;
  letter-spacing: normal;
}

.subContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: getSpacing(2);
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $pengwinBlack;
}

.nextArrow {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 48%;
  cursor: pointer;
  z-index: $indexValue;
}

.prevArrow {
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 48%;
  cursor: pointer;
  z-index: $indexValue;
}

.lowerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: getSpacing(10);
  @media screen and (min-width: 1024px) {
    padding: getSpacing(2);
  }

  @media screen and (min-width: 1366px) {
    padding: getSpacing(10);
  }

  @media screen and (min-width: 1440px) {
    padding: getSpacing(10);
  }

  @media screen and (min-width: 1650px) {
    padding: getSpacing(10);
  }

  @media screen and (min-width: 1920px) {
    padding: getSpacing(10);
  }

  @media screen and (min-width: 2048px) {
    padding: getSpacing(10);
  }
}

.lowerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: getSpacing(5);
  width: 70%;
  @media screen and (min-width: 1024px) {
    padding-left: getSpacing(5);
  }

  @media screen and (min-width: 1366px) {
    padding-left: getSpacing(20);
  }

  @media screen and (min-width: 1920px) {
    padding-left: getSpacing(45);
  }
}

.lowerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  @media screen and (min-width: 1024px) {
    padding-right: getSpacing(5);
    gap: getSpacing(2);
  }

  @media screen and (min-width: 1366px) {
    padding-right: getSpacing(20);
    gap: getSpacing(4.5);
  }

  @media screen and (min-width: 1920px) {
    padding-right: getSpacing(20);
    gap: getSpacing(4.5);
  }
}

.head {
  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 26px;
  }
  line-height: 28.8px;
  text-align: left;
  font-weight: 700;
}

.subHead {
  @media screen and (min-width: 1024px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 36px;
  }
  line-height: 44px;
  text-align: left;
  font-weight: 700;
}

.textPara {
  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
  line-height: 20.2px;
  text-align: left;
  font-weight: 600;
  color: $pengwinBlack;
}

.mission {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 1024px) {
    padding: getSpacing(5) getSpacing(5);
  }

  @media screen and (min-width: 1366px) {
    padding: getSpacing(5) getSpacing(20);
  }

  @media screen and (min-width: 1920px) {
    padding: getSpacing(5) getSpacing(30);
  }
  background-color: $pengwinFadedBlack;
}

.missionA {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 1024px) {
    padding: getSpacing(5) getSpacing(5);
  }

  @media screen and (min-width: 1366px) {
    padding: getSpacing(5) getSpacing(20);
  }

  @media screen and (min-width: 1920px) {
    padding: getSpacing(5) getSpacing(30);
  }
  background-color: $pengwinFadedBlack;
}

.missionHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(10);
  width: 40%;
  text-align: left;
}

.missionHeadA {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(2);
  width: 80%;
  text-align: justify;
}

.missionText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getSpacing(5);
  width: 50%;
  text-align: left;
}

.missionTextA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: getSpacing(2);
  width: 50%;
  text-align: center;
}

.rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: getSpacing(2);
}

.headA {
  font-size: 36px;
  line-height: 37.8px;
  text-align: center;
  font-weight: 700;
}

.subHeadA {
  font-size: 18px;
  line-height: 27.2px;
  text-align: center;
  font-weight: 600;
  color: $pengwinBlack;
}

.tech_partners_container {
  text-align: center;
  margin: 20px 0;
}

.section_title {
  font-size: 50px;
  color: $pengwinBlack;
  line-height: 60px;
  text-align: center;
  margin-bottom: 30px;
}

.partners_grid {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.partner_card {
  border: 1px solid $pengwinBlack;
  background-color: $pengwinWhite;
  padding: getSpacing(4);
  border-radius: getSpacing(2);
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner_logo {
  width: 100px;
  height: 100px;
}
