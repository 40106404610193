@import "../../stylesheet.scss";

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $pengwinBlack;
  color: $pengwinWhite;
  @media screen and (min-width: 1024px) {
    padding: 20px getSpacing(10);
  }

  @media screen and (min-width: 1366px) {
    padding: 20px getSpacing(30);
  }

  @media screen and (min-width: 1920px) {
    padding: 20px getSpacing(50);
  }
  text-align: left;

  .section {
    width: 25%;

    .head {
      font-size: 16px;
      line-height: 20.8px;
      text-align: start;
      font-weight: bold;
      color: $pengwinWhite;
    }

    .email {
      color: $pengwinWhite;
      text-decoration: none;
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.copyright {
  background-color: $pengwinBlackGlossy;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: $pengwinWhite;
}

.companySubText{
  margin-top: 16px;
  margin-bottom: 37px;
  line-height: 27.2px;
  letter-spacing: normal;
}